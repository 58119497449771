import React from "react";
import BasicTable from "./Table";
import { orderTableHead, orderTableRows } from "../constants";
import ParagraphTitle from "./PageComponents/ParagraphTitle";
import Paragraph from "./PageComponents/Paragraph";

const SupportedMapTypes = () => {
  return (
    <>
      <ParagraphTitle text="Supported data delivery products" />
      <Paragraph text="Delivery is separated into two groups: 'baseFiles' that consist of the unprocessed individual jpg images that the drones capture, and the processed data that contains all the processed delivery products listed below." />
      <Paragraph text="Note that the processed data contains only the delivery products that you specified in the order request." />
      <BasicTable head={orderTableHead} rows={orderTableRows} />
    </>
  );
};

export default SupportedMapTypes;
