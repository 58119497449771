import React from "react";
import BasicTable from "./Table";
import {
  advancedOptionsTableHead,
  advancedOptionsTableRow,
} from "../constants";
import ParagraphTitle from "./PageComponents/ParagraphTitle";
import Paragraph from "./PageComponents/Paragraph";

const AdvancedOptions = () => {
  return (
    <>
      <ParagraphTitle text="Advanced options details" />
      <Paragraph text="You can specify how you want your data to be captured by communicating the details specs. Otherwise, the standard GLOBHE specs will be applied." />
      <BasicTable
        head={advancedOptionsTableHead}
        rows={advancedOptionsTableRow}
      />
    </>
  );
};

export default AdvancedOptions;
