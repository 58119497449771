import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

const BasicTable = ({ head, rows }) => {
  return (
    <TableContainer component={Paper} sx={{ width: "auto", margin: "50px 0" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {head.map((item, index) => (
              <TableCell key={index} sx={{ fontWeight: "bold" }}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.item}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.item}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.complementaryInfo}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
BasicTable.propTypes = {
  head: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};
export default BasicTable;
