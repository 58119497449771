import React, { useState } from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import {
  mapTypesOption,
  endpoints,
  optionalEndpoints,
  orderJsonExample,
  orderResponseJsonExample,
  missionMapTypes,
} from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import {
  codeFont,
  listItems,
  listStyle,
  pageStyles,
  pageContent,
} from "../../styleConstants";
import SupportedMapTypes from "../../components/SupportedMaptypes";
import AdvancedOptions from "../../components/AdvancedOptions";
import AlertInfo from "../../components/PageComponents/AlertInfo";
import VersionSwitch from "../../components/VersionSwitch";
import PageTitle from "../../components/PageComponents/PageTitle";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import Paragraph from "../../components/PageComponents/Paragraph";

const IndexPage = () => {
  const [index, setIndex] = useState(1);

  const handleIndexChange = (newIndex) => {
    setIndex(newIndex);
  };

  const getTheLabel = (key) => {
    let mapTypeLabel = missionMapTypes.filter(
      (mapType) => mapType.value === key
    );
    return mapTypeLabel[0]?.label || "";
  };

  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={4} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <AlertInfo text="Please note that the ordering API currently supports only single-location orders. If you need to create a multi-location or recurring order, please place a separate single order for each site." />
          <PageTitle text="Create Order" />

          <VersionSwitch handleIndexChange={handleIndexChange} index={index} />
          <Paragraph
            text="Order drone data for a specific area of interest. The order endpoint
            must include the following items:"
          />
          <ul style={listStyle}>
            {endpoints.map(({ code, description }) => (
              <li key={code} style={listItems}>
                <code style={codeFont}>{code}</code> : {description}
              </li>
            ))}

            <li style={listItems}>
              <code style={codeFont}>mapTypes</code> : represents the end
              product that the client is requesting. Currently supporting only{" "}
              {mapTypesOption.map((item, index) => (
                <div key={index}>
                  <code key={index} style={codeFont}>
                    {item}
                  </code>{" "}
                  : {getTheLabel(item)}
                  &nbsp;
                </div>
              ))}
            </li>
          </ul>
          <Paragraph text=" The order endpoint can include the following:" />

          <ul style={listStyle}>
            {optionalEndpoints.map(({ code, description }) => (
              <li key={code} style={listItems}>
                <code style={codeFont}>{code}</code> : {description}
              </li>
            ))}
          </ul>
          <AdvancedOptions />
          <SupportedMapTypes />
          <ParagraphTitle text="Example Request" />

          {index === 0 ? (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v1/orders" />
          ) : (
            <CodeContainer text="POST  https://us-central1-globhe-app.cloudfunctions.net/api/v2/orders" />
          )}
          <JsonContainer text={orderJsonExample} />
          <ParagraphTitle text="Example Response" />

          <JsonContainer text={orderResponseJsonExample} />
        </Container>
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
